@import "../../../styles/theme";

.root {
  // default to the color of the current font
  fill: currentColor;

  &.primary {
    fill: $black;
  }
  &.size-small {
    height: 10px;
    width: 10px;
    font-size: 10px;
  }

  &.size-regular {
    height: 16px;
    width: 16px;
    font-size: 16px;
  }

  &.size-medium {
    height: 22px;
    width: 22px;
    font-size: 22px;
  }

  &.size-large {
    height: 32px;
    width: 32px;
    font-size: 32px;
  }

  &.size-xlarge {
    height: 48px;
    width: 48px;
    font-size: 48px;
  }

  &.size-fill {
    height: 1em;
    width: 1em;
    font-size: 1em;
  }
}
