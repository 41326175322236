@mixin xm-mobile(){
  @media screen and (max-width: $xm-mobile - 1px) {
    @content;
  }
}

// Responsiveness
@mixin from($device){
  @media screen and (min-width: $device){
    @content
  }
}

@mixin from-to($fromDevice, $toDevice) {
  @media screen and (min-width: $fromDevice) and (max-width: $toDevice) {
    @content
  }
}

@mixin sm-mobile(){
  @media screen and (max-width: $sm-mobile - 1px) {
    @content;
  }
}

@mixin mobile(){
  @media screen and (max-width: $tablet - 1px) {
    @content;
  }
}

@mixin until($device){
  @media screen and (max-width: $device - 1px) {
    @content;
  }
}

@mixin tablet(){
  @media screen and (min-width: $tablet), print {
    @content;
  }
}

@mixin touch(){
  @media screen and (max-width: $desktop - 1px) {
    @content;
  }
}

@mixin touch-page(){
  @media screen and (max-width: $desktop-page - 1px) {
    @content;
  }
}

@mixin desktop(){
  @media screen and (min-width: $desktop) {
    @content;
  }
}
@mixin widescreen(){
  @media screen and (min-width: $winsceen) {
    @content;
  }
}

/*========= button mixin ======*/
@mixin btn-primary($bgColor, $textColor: $white){
  background-color: $bgColor;
  border: 2px solid $bgColor;
  color: $textColor;

  @include from($desktop){
    &:hover{
      color: $bgColor;
      background-color: transparent;
    }
  }
}

@mixin btn-secondary($color, $bgColor){
  color: $color;
  border: 2px solid $color;
  background-color: $bgColor;

  @include from($desktop){
    &:hover{
      background-color: $color;
      color: $bgColor;
    }
  }
}
