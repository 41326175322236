@use "sass:color";
@import '../../../styles/theme.scss';

.root {
  position: relative;
  border-radius: 50%;
  margin: 0;
  padding: 0;
  border: 2px solid $white;
}

.color-default {
  background-color: $black;
  color: $white;

  &:hover {
    background-color: lighten($black, 20);
  }

  &:focus {
    background-color: darken($black, 5);
  }
}

.color-success {
  background-color: $success-dark;
  color: $white;

  &:hover {
    background-color: lighten($success-dark, 20);
  }

  &:focus {
    background-color: darken($success-dark, 5);
  }
}

.size-default {
  width: 32px;
  height: 32px;
}

.size-large {
  width: 44px;
  height: 44px;
}

.iconWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0)
}