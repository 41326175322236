@import '../../../styles/theme';

.root {
  min-width: 64px;;
  max-width: 264px;
  min-height: 64px;
  position: relative;
  flex-shrink: 0;
  padding: $spacing-unit $spacing-unit * 2;
  background-color: #f7f7f7;
  color: black;
  overflow: hidden;
  white-space: normal;
  scroll-snap-align: start;
  font-size: 24px;
  font-weight: 600;
  border: 1px solid $grey;
  display: block;
  border-radius: 0;

  &:not(.fullWidth) {
    min-width: 55vw;
  }
  & + .root {
    margin-left: -1px;
  }
}


.selected {
  background-color: $black;
  color: white;
}

.completed {
  border: 1px solid $success-dark;
  color: $success-dark;
  z-index: 1;
  &.selected {
    background-color: $success-dark;
    color: $white;
  }
}

.disabled {
  color: $grey;
}

.dense {
  min-height: 48px;
  font-size: 16px;
}

.fullWidth {
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 0;
  min-width: none;
  max-width: none;
}

.wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: row;
  color: inherit;

  :nth-child(2) {
    margin-left: $spacing-unit * 2;
  }
}

.label {
  @include desktop() {
    transform: translateY(2px);
  }
}