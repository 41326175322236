@import '../../../styles/theme';

.tag {
  display: inline-block;
  margin-bottom: $spacing-unit * 0.5;
  padding: $spacing-unit * 2;
  margin-right:$spacing-unit * 1.5;
  line-height: 14px;
  border: 1px solid #dbdbdb;
  background: $white;
  color: #999999;
  cursor: pointer;
  outline: none;
}

.times {
  color: $black;
  font-size: 12px;
  margin-left: $spacing-unit * 1.5;
}