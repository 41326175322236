@import '../../../styles/theme';

.icon {
  width: 40px!important;
  height: 60px!important;
  color: $black;

  &.white {
    color: white;
  }
}