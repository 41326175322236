.root {
  min-height: 48;
  overflow: hidden;
  display: flex;
}

.scrollable {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: 0;
  scroll-snap-type: x mandatory;
  &::-webkit-scrollbar {
    display: none;
  }
}