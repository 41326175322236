@import '../../../styles/theme';

.root {
  font-size: 1rem;
  line-height: 30px;
  vertical-align: middle ;
  display: inline-block;
  text-transform: capitalize;
  position: relative;
  cursor: pointer;
  letter-spacing: normal;
}

.checkbox {
  display: none !important;
}

.checkboxContent {
  margin-left: $spacing-unit * 5.5;
  color: $black;
  font-weight: 400;

  &.small {
    margin-left: $spacing-unit * 3;
    font-size: 12px;
  }
}

.checkMark {
  width: 20px;
  height: 20px;
  border: 2px solid $black;
  position: absolute;
  display: inline-block;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);

  &.small {
    width: 14px;
    height: 14px;
    border: 1px solid $black;
  }
}

.active {
  background: $black;
}

.tick {
  display: none;
  color: $white;

  &.small {
    font-size: 9px;
  }
}

.show {
  position: absolute;
  left: 50%;
  top: 50%;
  display: inline-block;
  transform: translate(-50%,-50%);
}

.white {
  color: $white!important;
  border-color: $white!important;
}