@import '../../../styles/theme';

.root {
  display: inline-flex;
  position: relative;
  height: 36px;
}

.primaryButton {
  padding: 0 $spacing-unit * 2;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  height: 100%;
  border-radius: 36px;
  background-color: $white;
  border: 2px solid $black;

  &.iconShift {
    padding-right: 38px;
  }

  &[role='button']:not(.disabled):hover {
    border-color: #444;
    color: #444;
    cursor: pointer;
  }

  &.disabled {
    opacity: 0.5;
    cursor: inherit;
  }
}

.secondaryButton {
  font-weight: 700;
  position: absolute;
  top: 2px;
  right: 2px;
  height: 32px;
  width: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border: 2px solid $white;
  color: $white;
  background-color: $black;
  transition: transform 250ms ease;
  line-height: 1;

  &[role='button']:not(.disabled):hover {
    transform: scale(1.15);
    background-color: #444;
    cursor: pointer;
  }

  &.disabled {
    background-color: $grey !important;
    &:hover {
      cursor: inherit;
    }
  }
}
