@import "../../../styles/theme";

.fullWidth {
  height: 1px;
  width: 100%;
  color: #EEEEEE;
  border-color: #EEEEEE;
  background-color: #EEEEEE;
}

.IconContainer {
  flex-grow: 0!important;
  width: 48px;
  margin: 0 $spacing-unit;
}
